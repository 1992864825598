<template>
  <div id="Environment" class="row">
    <div class="col-12">
      <div class="row header pt-2">
        <h2 class="col-12">
          <span class="fas fa-chevron-right"></span>Environmental Impact
        </h2>
      </div>
      <div class="row stretch d-none d-md-block">
        <div class="col-1">&nbsp;</div>
      </div>
      <div id="FactPanels" class="row px-0 pl-lg-3 pt-3">
        <div class="col-12 col-lg-6 mb-5 mb-lg-3">
          <div class="row">
            <div
              class="col-12 mb-3 mb-lg-auto col-lg-6"
              v-for="(panel, $idx) in environmentalImpactPanels"
              :key="$idx"
            >
              <div class="row">
                <div class="col-2 col-md-1 col-lg-4 pt-0 pt-3 pr-0">
                  <img
                    class="img-fluid"
                    :src="panel.imgSrc"
                    :alt="panel.altText"
                  />
                </div>
                <div class="col-8 pt-md-3 pt-lg-auto">
                  <h4>{{ panel.title }}</h4>
                  <p v-html="panel.descriptionHtml"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-0">Generating electricity creates emissions...</h3>
              <h5>
                {{ state }}'s electricity comes from the following sources:
              </h5>
            </div>
          </div>

          <div class="row my-3 text-center text-lg-left" v-if="energySources">
            <div
              class="col-6 col-lg-4 px-0"
              v-for="(source, idx) in energySources"
              :key="idx"
            >
              <div class="row no-gutters">
                <div class="col-12 col-lg-auto pr-1">
                  <img :src="getEnergySourceImg(source)" />
                </div>
                <div class="col-12 col-lg pt-2">
                  <div class="energy-source-percent">
                    {{ toPercent(source.Percentage) }}
                  </div>
                  <div class="energy-source">
                    {{ source.Source.replace("_", " ") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants } from "Services/Constants";
import common from "Mixins/common";

export default {
  props: ["vehicle"],
  methods: {
    getEnergySourceImg(source) {
      let imgName;
      switch (source.Source) {
        case "Coal":
          imgName = "coal";
          break;
        case "Petroleum":
          imgName = "gasoline";
          break;
        case "Natural_Gas":
          imgName = "natural_gas";
          break;
        case "Hydroelectric":
          imgName = "hydro";
          break;
        case "Renewable":
          imgName = "solar";
          break;
        case "Nuclear":
          imgName = "nuclear";
          break;
        default:
          imgName = source.Source;
          break;
      }
      return require(`Images/${imgName}.png`);
    },
  },
  computed: {
    state() {
      return this.$store.state.locationInfo?.address?.state_full;
    },
    emissionsData() {
      return this.$store.state.locationInfo?.local_emissions_data;
    },
    energySources() {
      if (this.emissionsData?.Energy_Sources) {
        return [...this.emissionsData?.Energy_Sources].sort(
          (a, b) => b.Percentage - a.Percentage
        );
      }
      return [];
    },
    evTonsCO2() {
      const tonsCO2 =
        ((this.emissionsData?.Grams_CO2_per_kWh || 0) *
          (this.vehicle.Battery_Size / this.vehicle.Range) *
          Constants.nationalMilesDrivenAverage) /
        1000000;
      return Math.round(tonsCO2 * 100) / 100;
    },
    tonsCO2Saved() {
      return (
        Math.round((Constants.gasCarCO2Annually - this.evTonsCO2) * 100) / 100
      );
    },
    savedTrees() {
      return Math.round(this.tonsCO2Saved * Constants.treesSavedPerTonCO2);
    },
    ymm() {
      return `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model}`;
    },
    milesDriven() {
      return common.toNumber(Constants.nationalMilesDrivenAverage);
    },
    Constants() {
      return Constants;
    },
    environmentalImpactPanels() {
      return [
        {
          title: "Gasoline",
          descriptionHtml: `The average gas-powered car driven ${this.milesDriven} miles a
                    year is directly responsible for producing
                    ${Constants.gasCarCO2Annually} tons of CO<sup>2</sup>'`,
          imgSrc: require("Images/car.png"),
          altText: "Front facing car icon",
        },
        {
          title: "Electricity",
          descriptionHtml: `A ${this.ymm} driven ${this.milesDriven} miles a year is indirectly
                responsible for producing ${this.evTonsCO2} tons of CO<sup>2</sup>
                in ${this.state}`,
          imgSrc: require("Images/power.png"),
          altText: "Electric vehicle charger icon",
        },
        {
          title: "Emissions",
          descriptionHtml: `Driving a ${this.ymm} in ${this.state} will reduce your CO<sup>2</sup>
                            emissions by ${this.tonsCO2Saved} tons per year versus a gas vehicle`,
          imgSrc: require("Images/leaf.png"),
          altText: "Falling leaf icon",
        },
        {
          title: "Savings",
          descriptionHtml: `Taking ${this.tonsCO2Saved} tons of CO<sup>2</sup> out of the air each
            year is comparable to planting ${this.savedTrees} trees`,
          imgSrc: require("Images/tree.png"),
          altText: "Evergreen tree icon",
        },
      ];
    },
  },
  mixins: [common],
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(sm) {
  h2 {
    font-size: 1.35rem;
  }
}
h2 {
  font-size: 1.5rem;
}
h3 {
  color: white;
  font-size: 1.375rem;
}

h5 {
  font-size: 0.875rem;
}

span.fa-chevron-right {
  color: #9ed766;
  padding-right: 10px;
}

#Environment {
  background-color: #75d4a8;
  color: #2d4724;

  .header {
    background-color: #2d4724;
    border-bottom: 10px solid #5db990;
    color: white;
    font-family: $primary-header-font-family;
  }

  .energy-source-percent {
    color: white;
    font-size: 1.25rem;
  }

  .energy-source {
    font-weight: bold;
    font-size: 0.875rem;
  }

  #FactPanels {
    font-size: 1.25rem;

    h4 {
      color: white;
      margin-bottom: 2px;
      font-size: 0.9375rem;
    }

    p {
      font-weight: normal;
      font-size: 0.75rem;
    }
  }
}
</style>
